@import url("../fonts/expansiva/style.css");

.body {
  height: auto;
  overflow: auto;
}

.sidebar-with-background {
  position: fixed;
}

.Modal-module_modalTitle__1TtY9 {
  font-size: 20px !important;
  font-weight: 700;
  color: #000000 !important;
}

.form-label {
  color: #000000 !important;
  font-family: "Intern" !important;
  font-weight: 500;
}

.label-color {
  margin-bottom: 12px;
}

.flexandsplit {
  display: flex;
  justify-content: space-between;
  gap: 2;
  width: 100%;
}
.add-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #0dda21 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #0dda21 !important;
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  color: #000000 !important;
  background-color: #0dda21 !important;
  border-color: #0dda21 !important;
}

.sidebar-background-image {
  /* background-image: url('../assets/brand/cover-image.png'); */
  /* Add other background image styling properties as needed */
}

.grid-table {
  width: 100%;
  border-collapse: collapse;
}

.grid-table th,
.grid-table td {
  padding: 10px;
  border: 1px solid #ccc;
  color: green;
}

.grid-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.grid-table td {
  text-align: center;
}

.GridParagraph {
  margin: 5px;
  color: green;
}

.GridComponentDiv {
  margin: 10px;
}

.ScanHardware-main {
  /* background-image: url('../assets/brand/NewtonInsightsBranding/NIBackground.png'); */
  /* background-size: 1700px 1000px; */
  /* This will make the image cover the entire container */
  /* background-repeat: no-repeat; */
  /* Prevent the image from repeating */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.body {
  flex-grow: 1;
}
/* Primary Button */
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
  padding: 0px 10px;
  font-size: 16px;
  font-family: "Inter";
  width: 250px;
  /* font-weight: 600; */
  height: 50px;
  /* padding: 0.75rem 0.75rem;
  font-size: 1.2rem;
  width: 250px;
  height: 80px; */
  margin-left: 10px;
}

.backBtn {
  display: flex;

  justify-content: center;
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.5);
}

/* Success Button */
.btn-success {
  color: #000000;
  /* background-color: #28a745; */
  background-color: #0dda21;
  border-color: #0dda21;
  /* border-color: #28a745; */
  padding: 0.75rem 0.75rem;
  font-size: 16px;
  width: 250px;
  height: 50px;
  font-family: "Inter";
  /* font-size: 1.2rem;
  width: 250px;
  height: 80px; */
}

.btn-success:hover {
  color: #000000;
  background-color: #0dda21;
  border-color: #0dda21;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.body-with-background {
  /* background-image: url('../assets/cover-image.png'); */
  background-repeat: no-repeat;

  background-size: contain;
  /* 1Adjust this based on how you want the image to fit */
}

.BannerStyle {
  width: 100%;
  height: 100px;
  background: linear-gradient(135deg, #2e3192 0%, #1bffff 100%);
  font-size: 36px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.CContainerStyle {
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.DContainerStyle {
  display: flex;
  flex-direction: column;
}

.inputData {
  float: right;
  clear: both;
}

.numericUpDown {
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  width: 300px;
  height: 35px;
  float: right;
}

select {
  /* styling */
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;

  width: 450px;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.spinnerStyle {
  width: 4rem;
  height: 4rem;
  margin-left: 100px;
  align-items: center;
  justify-content: center;
}

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
}

.submitBtn {
  padding: 1rem 2rem;
  background-color: #19d22c;
  color: black;
  font-size: 20px;
  width: 250px;
  height: 60px;
}

.centered-container {
  height: 50px;
  display: flex;
  justify-content: center;
}

/* arrows */

select.classic {
  background-image: linear-gradient(45deg, transparent 50%, blue 50%),
    linear-gradient(135deg, blue 50%, transparent 50%),
    linear-gradient(to right, skyblue, skyblue);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

select.classic:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    linear-gradient(to right, gray, gray);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em, 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
  border-color: grey;
  outline: 0;
}

.header {
  background-color: #f4f5f8;
}

.header-img {
  width: 200px;
  /* Set the desired width */
  height: 100px;
  /* Set the desired height */
}

.header-text {
  color: #000000;
  font-size: 35px;
  font-weight: 700;
  /* font-family: 'Inter' !important; */
  font-family: "Expansiva Regular", sans-serif;
  /* color: black;
  font-size: 42px;
  font-weight: bold;
  font-family: 'Courier New', Courier, monospace; */
  /* font-family: 'Expansiva', sans-serif; */
}
.justified-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.justified-line {
  display: flex;
  justify-content: space-between;
}

.justified-line::before,
.justified-line::after {
  content: "";
  flex: 1;
}

.input-text {
  color: #000000;
  font-size: 47px;
  font-weight: 600;
  font-family: "Inter";
  margin-bottom: 40px;
  margin-top: -40px;
}

.percentage {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0dda21;
  /* background-color: #4aa6ed; */
  color: white;
  /* font-size: 70px; */
  font-weight: 500;
  border-radius: 40px;
  font-size: 60px;
  /* width: 300px;
  height: 100px; */
  /* width: 350px;
  height: 140px; */
}

.percentage-text {
  color: #000000;
  font-family: "Inter" !important;
  /* margin-left: 30px; */
  font-size: 30px;
  text-align: center;
}

.percentage-background-image {
  height: 300px;
  width: 40%;
  /* Add other background image styling properties as needed */
}

.text-line {
  text-align: center;
}

@font-face {
  font-family: "Expansiva";
  src: url("../fonts/expansiva/Expansivabold.woff") format("woff");
}

/* custom classes */

.grid-table-2 {
  width: 100%;
  border-collapse: separate;
  border: none;
  border-spacing: 20px;
  font-size: 1.5rem;
  font-weight: 600;
}

.grid-table-2 th,
.grid-table-2 td {
  padding: 0px;
  border: 1px solid #ccc;
  /* color: black; */
  color: #000000;
  font-size: 17px;
  font-family: "Inter" !important;
}

.grid-table-2 th {
  /* background-color: #f2f2f2; */
  font-family: var(--cui-body-font-family);
  /* font-size: 1.2rem; */
  /* font-weight: 700; */
  /* word spacing */
  letter-spacing: 0.9px;
}

.grid-table-2 td {
  text-align: center;
  font-weight: 600;
}

.grid-table-2,
tr {
  gap: 10px;
}

.grid-table-2,
th,
td,
tr {
  border: none !important;
}

.red-text {
  color: red;
}

.green-text {
  color: green;
}

.btn-bg-2 {
  background-color: #d9d9d9;
  /* background-color: #c0c0c0; */
  cursor: pointer;
}

/* add a buttn group class */
.btn-group {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 30px;
}

.loader {
  margin: 0 auto;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(13, 218, 33);
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

.stripe-input {
  border: 1px solid #d6e2eb;
  padding: 0.7rem;
  background: rgba(234, 240, 245, 0.4);
  border-radius: 0.7rem;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  margin: 0.5rem 0;
  width: 100%;
}
.card-number-container {
  width: 100%;
}

.cvv,
.card-expiration-date {
  width: 40%;
}

.card-element-label {
  margin: 0 0 0 0.3rem;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.first-box {
  border: 1px solid;
  padding: 15px 35px;
  border-radius: 5px;
  justify-content: center;
  display: flex;
  min-width: 170px;
  margin-bottom: 10px;
}

.font-graph {
  font-size: 20px;
  font-weight: 600;
  color: #000000;
}

.every-box {
  border: 1px solid #a5a5a5;
  border-color: #d3d5d5;
  box-shadow: 0px 0px 10px 10px #fffdfd;
}

.thc-graph {
  display: flex;
  direction: row;
}

.onlyMobile {
  display: none;
}
.no-margin {
  margin: 0;
}

.poniter {
  cursor: pointer;
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(255, 255, 255, 0.196);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

@media (max-width: 1000px) {
  .user-name {
    position: absolute;
    top: 60%;
    right: 10%;
  }
  .thc-graph {
    display: flex;
    flex-direction: column;
  }
  .result-values {
    margin-top: 10px;
  }
  .nav-action-icons {
    display: none;
  }
  .onlyMobile {
    display: block;
  }
  .user-name {
    position: absolute;
    top: 10px;
    right: 20px;
  }
}

@media (min-width: 800px) {
  .grid-table-2 th,
  .grid-table-2 td {
    padding: 10px;
  }
  select.classic {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .ScanHardware-main {
    width: 100%;
  }
  .header-img {
    width: 70%;
  }

  .header-text {
    font-size: 22px;
  }
  .user-name {
    position: absolute;
    top: 10%;
    right: 20px;
  }

  .label-color {
    margin-left: 70px;
    width: 50%;
  }
  select.classic {
    width: 120%;
  }
  .form-label {
    font-size: 20px;
  }
  .input-text {
    font-size: 35px;
  }
  .grid-table-2 td {
    padding: 0px;
  }
  .first-box {
    min-width: auto;
    width: 50%;
  }
  .onlyMobile {
    display: block;
  }
}
.mt-2rem{
    margin-top: 2rem !important;
}
.mt-1rem{
    margin-top: 1rem !important;
}
.text-red, p.error{
    color: red;
}
.cursor-pointer{
    cursor: pointer;
}
.locked-field {
    background-color: #ccc;
    cursor: not-allowed;
}
